<template>
    <div class="data">
        <Breadcrumb :currentPage="currentPage"></Breadcrumb>

        <TitleTabs :tabsData="tabsData" @changeTab="changeTab"></TitleTabs>
        <TabItems @changeItem="changeItem" :tabCurrentIndex="secondTabCurrentIndex"></TabItems>
        <Introduce @changeDownload="changeDownload"  v-if="secondTabCurrentIndex===0" :introduce="introduceData"></Introduce>
        <Teach v-else-if="secondTabCurrentIndex===1" :teachData="teachData"></Teach>
        <Problem v-else-if="secondTabCurrentIndex===2" :problemData="problemData"></Problem>
        <Download v-else-if="secondTabCurrentIndex===3" :downloadData="downloadData"></Download>
    </div>
</template>

<script>
import Breadcrumb  from '../../components/Breadcrumb'
import TitleTabs from '../../components/TitleTabs'
import TabItems from '../../components/TabItems'
import Introduce from '../../components/Introduce'
import Teach from '../../components/Teach'
import Problem from '../../components/Problem'
import Download from '../../components/Download'
export default {
    name: 'Data',

    data() {
        return {
            currentPage: '数据管理',

            firstTabCurrentIndex: 0,

            tabsData: [],
            secondTabCurrentIndex: 0,

            introduceData: {},

            teachData: {},

            problemData: {},

            downloadData: {}
        };
    },

    mounted() {
        this.getTitleTabs() 
        this.getDataIntroduce()
    },

    methods: {
        changeDownload() {
            this.secondTabCurrentIndex = 3
            this.getDataDownload()
        },

        changeTab(firstIndex) {

        },
        
        changeItem(secondIndex) {
            console.log('secondIndex: ', secondIndex);
            this.secondTabCurrentIndex = secondIndex

            if(this.secondTabCurrentIndex === 0) {
                this.secondTabCurrentIndex = 0
                this.getDataIntroduce()
            }else if(this.secondTabCurrentIndex === 1) {
                this.secondTabCurrentIndex = 1
                this.getDataTeach()
            }else if(this.secondTabCurrentIndex === 2) {
                this.secondTabCurrentIndex = 2
                this.getDataProblem()
            }else if(this.secondTabCurrentIndex === 3) {
                this.secondTabCurrentIndex = 3
                this.getDataDownload()
            } 
        },

        async getTitleTabs() {
            const { code, data } =  await this.$http.get('/dataTabs')
            if(code === 200) {
                this.tabsData = data
            }

            this.secondTabCurrentIndex = 0
            this.firstTabCurrentIndex = 0
        },

        async getDataDownload() {
            const { code, data } = await this.$http.get('/dataDownload')
            if(code === 200) {
                this.downloadData = data
            }
        },

        async getDataIntroduce() {
            const { code, data } = await this.$http.get('/dataIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        },

        async getDataTeach() {
            const { code, data } = await this.$http.get('/dataTeach')
            if (code === 200) {
                this.teachData = data
            }
        },

        async getDataProblem() {
            const { code, data } = await this.$http.get('/dataProblem')
            if (code === 200) {
                this.problemData = data
            }
        }
    },

    components: {
        TitleTabs,
        TabItems,
        Introduce,
        Teach,
        Problem,
        Download,
        Breadcrumb
    },
};
</script>

<style lang="less" scoped>

</style>